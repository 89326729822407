import React from 'react'
import PropTypes from "prop-types"
import Img from 'gatsby-image'
import { Link } from "gatsby"
import Layout from "../components/Layout"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SEO from "../components/seo"

class PostTemplate extends React.Component{
    render(){
        const post = this.props.data.wordpressPost
        const { previous, next } = this.props.pageContext;
        return(
            <Layout>
            <SEO title={post.title} keywords={[`telekomunikacije`, `inžinjering`]} />
            <div id={"_post_"+post.id} className="post">
                <section className="section page-title single-blog">
                    <div className="container">
                    <h1 dangerouslySetInnerHTML={{__html: post.title }} className="title"/>
                    </div>
                </section>

                <section className="page-news-header page-news-header-blog">
                    <div className="container">
                        <div className="is-left date_news">
                            <Link to="/novosti"><span className="category">Novosti</span></Link>
                            <span className="date">{post.date}</span>
                        </div>

                        <div className="is-right share">
                            <a href=""><FontAwesomeIcon icon={['fab', 'facebook-f']} aria-hidden="true" /></a>
                            <a href=""><FontAwesomeIcon icon={['fab', 'twitter']} aria-hidden="true" /></a>
                            <a href=""><FontAwesomeIcon icon={['fas', 'envelope']} aria-hidden="true" /></a>
                        </div>
                    </div>
                </section>

                <section className="section page-content blog-content">
                   <div className="container">
                            <img src={post.featured_media.localFile.childImageSharp.resolutions.src} alt={post.title} />
                        <div dangerouslySetInnerHTML={{__html: post.content }} className="main" />

                        <ul className="post-nav">
                            <li>
                            {previous && (
                                <Link to={`/${previous.slug}`} rel="prev" className="button _btnHeader ">
                                ← Prethodni članak
                                </Link>
                            )}
                            </li>
                            <li>
                            {next && (
                                <Link to={`/${next.slug}`} rel="next" className="button _btnHeader ">
                                Sljedeći članak →
                                </Link>
                            )}
                            </li>
                        </ul>

                   </div>
                </section>
            </div>
            </Layout>
        )
    }
}



export default PostTemplate

export const postQuery = graphql`
    query currentPostQuery($id: String!) {
        wordpressPost(id: {eq: $id }){
        title
        id
        content
        date(formatString: "MMMM DD, YYYY")
        featured_media{
            localFile{
                childImageSharp{
                    resolutions(width:1920, height:1080){
                        src
                        width
                        height
                    }
                }
            }
        }
    }
    site{
        siteMetadata {
            title
            subtitle
        }
    }
}
`